import { Container, Grid, Typography, useMediaQuery, Box } from "@mui/material";
import React from "react";
import logoOne from "../Images/uniswap-logo.png";
import logoTwo from "../Images/MEXC-Logo.jpg";
import logoThree from "../Images/bitmart.png";
import logoFour from "../Images/coinw-logo.png";
import logoFive from "../Images/digifinex-logo.png";
import logoSix from "../Images/lbank.png";
import logoSeven from "../Images/p2b.png";
import logoEight from "../Images/bittrue.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyToClipboard from "react-copy-to-clipboard";
import { ToastNotify } from "../components/SmallComponents/AppComponents";
import { useState } from "react";
import linkIcon from "../Images/external-link.png";


export default function HowToBuy() {
  const matches = useMediaQuery("(max-width:950px)");
  const matches1 = useMediaQuery("(max-width:1390px)");
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const tabSx = {
    '& .MuiTabs-indicator': {
      backgroundColor: '#fff',
    },
    '& .MuiButtonBase-root.MuiTab-root': {
      color: '#fff',
      transition: 'color 0.2s ease-in-out',
      marginRight: '0px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '5px',
      minHeight: "48px",
      '&:hover': {
        color: 'green)',
      },
      '&.Mui-selected': {
        color: '#fff',
        background: 'radial-gradient(circle at 10% 20%, rgb(69, 86, 102) 0%, rgb(34, 34, 34) 90%)'
      },
      '& .MuiButtonBase-root.MuiTab-root:last-child': {
        marginRight: '0px',
      },
      '& .MuiTabPanel': {
        maxWidth: '954px',
        margin: '0 auto',
      }
    },
  };

  return (
    <>
     <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Container maxWidth="xl" id="howtobuy">
        <Box position="relative" zIndex={1} px={!matches && 8}>
          <Typography
            mb={matches ? 8 : 18}
            sx={{
              fontFamily: "'Poppins'",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: matches ? "30px" : "50px",
              color: "#FFFFFF",
              textAlign: "center",
              marginBottom: "0px",
              paddingTop: "45px",
            }}
          >
            <span
              style={{
                background: "transparent",
                backgroundImage:
                  "linear-gradient(90deg, rgba(109,216,212,1) 10%, rgba(243,137,192,1) 60%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              How to buy <br />
              <span className="logo-animation" style={{
                fontFamily: "'Poppins'",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: matches ? "30px" : "50px",
              }}>RETIK</span>
            </span>

          </Typography>
        </Box>
        <Grid className="card-grid card-grid-buy card-grid-buy-panel grid-gap-2 buy-top buy-top-xs">
          {/* <div className="ecosystem-card ecosystem-card-buy">
            <div className="">
              <h4 className="h4-title no-opcity h4-title-buy">Download a Wallet</h4>
            </div>
            <p className="buywallet-content">
              The wallet is accessible for download on Android, iOS, or PC, and it's conveniently available on all major app stores.
            </p>
          </div> */}
          <div className="ecosystem-card ecosystem-card-buy">
            <div className="">
              <h4 className="h4-title no-opcity h4-title-buy">Download & Connect your wallet</h4>
            </div>
            <p className="buywallet-content">
              The wallet is accessible for download on Android, iOS, or PC, and it's conveniently available on all major app stores.
            </p>
            <p className="buywallet-content">
              <ul style={{ paddingLeft: "15px" }}>
                <li>
                  Choose the "Connect" icon.
                </li>
                <li>
                  Select the wallet you wish to link.
                </li>
                <li>
                  Your wallet will then be prompted to authorize the connection. Once approved, your wallet will be successfully connected!
                </li>
              </ul>
            </p>
          </div>
          <div className="ecosystem-card ecosystem-card-buy">
            <div className="">
              <h4 className="h4-title no-opcity h4-title-buy">Get Some Coin</h4>
            </div>
            <p className="buywallet-content">
              Visit a prominent Centralized Exchange to acquire coins like ETH, USDT, or USDC. Consider platforms such as Coinbase, Binance, or explore a Decentralized Exchange (DEX). Increasing your coin holdings enables you to acquire more RETIK tokens.
            </p>
          </div>
          <div className="ecosystem-card ecosystem-card-buy">
            <div className="">
              <h4 className="h4-title no-opcity h4-title-buy">Buy Retik</h4>
            </div>
            <p className="buywallet-content">
              Once your wallet is connected to Uniswap, proceed by selecting the payment coin- such as ETH, USDT, USDC, and so on. Then, choose the RETIK token in the Receiving slot (or) Insert the RETIK Contract address, use address:</p> <span className="ca-text">0x26EbB8213fb8D66156F1Af8908d43f7e3e367C1d</span> <br />
              {/* <span className="copy-flex">
               <p style={{margin: "0"}}>
                  {matches
                    ? "0x26EbB8213...3f7e3e367C1d"
                    : "0x26EbB8213fb8D66156F1Af8908d43f7e3e367C1d"}
                    </p>
                  <CopyToClipboard
                    text={"0x26EbB8213fb8D66156F1Af8908d43f7e3e367C1d"}
                    onCopy={() => {
                      setAlertState({
                        open: true,
                        message: `Contract Address Copied.`,
                        severity: "success",
                      });
                    }}
                  >
                    <ContentCopyIcon
                      style={{ marginLeft: "10px", color: "#fff" }}
                    />
                  </CopyToClipboard>
                  </span> */}
              <p className="buywallet-content margin-5">Finally, click on the "Swap" button to complete the transaction.
            </p>
            <a href="https://app.uniswap.org/swap?&chain=mainnet&use=v3&outputCurrency=0x26EbB8213fb8D66156F1Af8908d43f7e3e367C1d" target="_blank" className="buy-a-color">Buy Retik <img src={linkIcon}/></a>
          </div>
        </Grid>
        <Grid className="card-grid card-grid-buy grid-gap-2-buy buy-top card-grid-md">
          <a href="https://app.uniswap.org/swap?&chain=mainnet&use=v3&outputCurrency=0x26EbB8213fb8D66156F1Af8908d43f7e3e367C1d" target="_blank" className="text-center no-line"><img src={logoOne} alt="" className="buy-logo-flex" />
            <div className="buy-ws-title">Uniswap</div>
          </a>
          <a href="https://www.mexc.com/exchange/RETIK_USDT" target="_blank" className="text-center no-line"><img src={logoTwo} alt="" className="buy-logo-flex ecosystem-card-buy-two" />
            <div className="buy-ws-title">MEXC</div>
          </a>
          <a href="https://www.bitmart.com/en-US" target="_blank" className="text-center no-line"><img src={logoThree} alt="" className="buy-logo-flex ecosystem-card-buy-two" />
            <div className="buy-ws-title">BitMart</div>
          </a>
          <a href="https://www.coinw.com/frontweb/en_US" target="_blank" className="text-center no-line"><img src={logoFour} alt="" className="buy-logo-flex ecosystem-card-buy-two" />
            <div className="buy-ws-title">CoinW</div>
          </a>
          <a href="https://www.digifinex.com/en-ww" target="_blank" className="text-center no-line"><img src={logoFive} alt="" className="buy-logo-flex ecosystem-card-buy-two" />
            <div className="buy-ws-title">DIGIFINEX</div>
          </a>
          <a href="https://www.lbank.com/" target="_blank" className="text-center no-line"><img src={logoSix} alt="" className="buy-logo-flex ecosystem-card-buy-two" />
            <div className="buy-ws-title">LBANK</div>
          </a>
          <a href="https://p2pb2b.com/" target="_blank" className="text-center no-line"><img src={logoSeven} alt="" className="buy-logo-flex ecosystem-card-buy-two" />
            <div className="buy-ws-title">p2B</div>
          </a>
          <a href="https://www.bitrue.com/" target="_blank" className="text-center no-line"><img src={logoEight} alt="" className="buy-logo-flex ecosystem-card-buy-two" />
            <div className="buy-ws-title">bitrue</div>
          </a>
        </Grid>
      </Container>
    </>
  );
}
