import React from "react";
import token from "../Images/image5.png";
import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import linktree from "../Images/linktree.png";
import tweet from "../Images/tweet.png";
import tele from "../Images/tele.png";
import youtube from "../Images/youtube.png";
import med from "../Images/med.png";
import arrowIcon from "../Images/right-arrow.png";
import { FaRedditAlien } from "react-icons/fa";
// import { CountdownMonths } from "./CountdownMonths";
import cmcLogo from "../Images/cmcicon.png";
import coingLogo from "../Images/cgicon.png";
import dexLogo from "../Images/dex.png";

export default function RealWorld() {
  const matches = useMediaQuery("(max-width:950px)");
  const matches1 = useMediaQuery("(max-width:1390px)");

  const Paragraph = styled(Typography)({
    fontWeight: 400,
    fontFamily: "'Poppins'",
    fontSize: "18px",
    lineHeight: "30px",
    color: "#fff",
  });

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={3}
          display="flex"
          alignItems="center"
          px={!matches && 3}
          py={matches ? 5 : 6}
          className="xs-reverse"
          justifyContent="center"
        >
  
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent="flex-end"
          >
            <Box
              pb={5}
              px={!matches && 5}
              sx={{
                width: matches ? "100%" : "80%",
                background: matches
                  ? "radial-gradient(54.8% 53% at 50% 50%, #151515 0, #15151500 100%), linear-gradient(152.97deg, #fff3, #fff0)"
                  : "#04040452",
                backdropFilter: "blur(30px)",
                borderRadius: matches ? "16px" : "25px",
                textAlign: "center",
                border: matches ? "1px solid #414247" : "1px solid #fff",
                height: "100%",
              }}
              className="md-padding"
            >
              <Typography
                py={2}
                sx={{
                  fontSize: matches ? "30px" : "3rem",
                  fontWeight: "700",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                <span
                  // style={{
                  //   background: "transparent",
                  //   backgroundImage:
                  //     "linear-gradient(90deg, rgba(109,216,212,1) 10%, rgba(243,137,192,1) 60%)",
                  //   WebkitBackgroundClip: "text",
                  //   WebkitTextFillColor: "transparent",
                  // }}
                  style={{
                    fontSize: matches ? "30px" : "3rem",
                    fontWeight: "700",
                    fontFamily: "Poppins",
                  }}
                  className="logo-animation"
                >
                  Retik{" "}
                </span>
                Finance

              </Typography>
              <a href="https://app.uniswap.org/swap?&chain=mainnet&use=v3&outputCurrency=0x26EbB8213fb8D66156F1Af8908d43f7e3e367C1d" target="_blank" className="buy-btn-center">
                  <div className="cardBox cardBox-buy">
                    <div className="card-animation card-animation-buy">
                      <div className="card-content">
                        <h3 className="card-title">Buy RETIK</h3>
                        {/* <div className="card-p">Earn rewards by staking Retik tokens</div> */}
                      </div>
                    </div>
                  </div>
                </a>
                <div className="panel-buy-logo">
                  <a href="https://coinmarketcap.com/rankings/exchanges/" target="_blank"><img src={cmcLogo}/></a>
                  <a href="https://www.coingecko.com/" target="_blank"><img src={coingLogo}/></a>
                  <a href="https://www.dextools.io" target="_blank"><img src={dexLogo} className="panel-buy-logo-3"/></a>
                </div>
           
              {/* <CountdownMonths /> */}


              {/* <Typography
                    pt={1}
                    sx={{
                      fontSize: matches ? "18px" : "22px",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    Tokens to Unlock:{" "}
                    <span
                      style={{
                        color: "gold",
                      }}
                    >
                      {formatNumberWithCommas(
                        parseFloat(nextUnlockTokens).toFixed(0)
                      )}
                    </span>{" "}
                    RETIK
                  </Typography> */}


            </Box>

          </Grid>
        </Grid>
      </Container>
    </>
  );
}
